import {
  post
} from "../Http";

export default {
  // 秒杀商品列表
  getSeckillProductList: params =>
    post(`/admin/v1/marketing/seckill/product/list`, params),
  // 导出秒杀商品列表
  ExportSeckillProductList: params =>
    post(`/admin/v1/marketing/seckill/product/export?actId=${params}`),
  // 秒杀商品新增或编辑
  saveSeckillProduct: params =>
    post(`/admin/v1/marketing/seckill/product/save`, params),
  // 秒杀商品新增或编辑
  updateSeckillProductSort: params =>
    post(`/admin/v1/marketing/seckill/product/update_sort`, params),
  // 秒杀广告列表
  getSeckillAdvList: params =>
    post(`/admin/v1/marketing/seckill/adv/list`, params),
  // 秒杀广告新增或编辑
  saveSeckillAdv: params =>
    post(`/admin/v1/marketing/seckill/adv/save`, params),


  // 批量上架商品
  setSeckillProductOnline: params =>
    post(`/admin/v1/marketing/seckill/product/online?productIds=${params}`),
  // 批量下架商品
  setSeckillProductOffline: params =>
    post(`/admin/v1/marketing/seckill/product/offline?productIds=${params}`),
  // 批量删除商品
  delSeckillProduct: params =>
    post(`/admin/v1/marketing/seckill/product/delete?productIds=${params}`),
  // 查询商品信息（根据商品ID）
  getSeckillProduct: params =>
    post(`/admin/v1/marketing/seckill/product/get?productId=${params}`),
  // 批量删除秒杀广告
  delSeckillAdv: params =>
    post(`/admin/v1/marketing/seckill/adv/delete?advertIds=${params}`),
  // 秒杀规则和分享信息获取
  getSeckillRule: params =>
    post(`/admin/v1/marketing/seckill/rule/get?actId=${params}`, {}, false),
  // 秒杀规则新增或编辑
  saveSeckillRule: params =>
    post(`/admin/v1/marketing/seckill/rule/save`, params, false),
  // 分享设置新增或编辑
  saveSeckillShare: params =>
    post(`/admin/v1/marketing/seckill/share/save`, params),

  // 集赞信息获取
  getCollectLike: params =>
    post(`/admin/v1/marketing/setting/collect_like/get?actId=${params}`),
  // 集赞信息新增或编辑
  saveCollectLike: params =>
    post(`/admin/v1/marketing/setting/collect_like/save`, params),
  // 公众号引流设置-获取
  getPublicAccount: params =>
    post(`/admin/v1/marketing/setting/public_account/get?actId=${params}`),
  // 公众号引流设置新增或编辑
  savePublicAccount: params =>
    post(`/admin/v1/marketing/setting/public_account/save`, params),
  // 分享奖励设置-查看，传入活动id。(尚未创建时返回null）
  getShareBonus: params =>
    post(`/admin/v1/marketing/setting/share_bonus/get?actId=${params}`, {}, false),
  // 分享奖励设置新增或编辑
  saveShareBonus: params =>
    post(`/admin/v1/marketing/setting/share_bonus/save`, params, false),

  // 营销设置-逛店设置-获取
  getGoShop: params =>
    post(`/admin/v1/marketing/setting/go_shop/get?actId=${params}`),
  // 逛店设置新增或编辑
  saveGoShop: params =>
    post(`/admin/v1/marketing/setting/go_shop/save`, params),

  // 优惠券列表
  getCouponList: params =>
    post(`/admin/v1/marketing/coupon/list`, params, false),
  // 批量启用优惠券
  setCouponOnline: params =>
    post(`/admin/v1/marketing/coupon/online?couponIds=${params}`),
  // 批量禁用优惠券
  setCouponOffline: params =>
    post(`/admin/v1/marketing/coupon/offline?couponIds=${params}`),
  // 批量删除优惠券
  setCouponDel: params =>
    post(`/admin/v1/marketing/coupon/delete?couponIds=${params}`),
  // 查看卡券信息，传入活动id。(尚未创建时返回null）
  getCouponCard: params =>
    post(`/admin/v1/marketing/coupon/card/get?actId=${params}`, {}, false),
  // 卡券信息新增或编辑
  saveCouponCard: params =>
    post(`/admin/v1/marketing/coupon/card/save`, params, false),
  // 新增/编辑优惠券，不传id时表示新增，新增成功后会返回优惠券id。添加优惠券前必须先建卡券（否则会抛异常）
  saveCoupon: params =>
    post(`/admin/v1/marketing/coupon/save`, params),

  // 店铺设置/进店攻略-查看，传入活动id。
  getPageShop: params =>
    post(`/admin/v1/activity/page/shop/get?actId=${params}`),
  // 新增或编辑店铺设置
  savePageShop: params =>
    post(`/admin/v1/activity/page/shop/save`, params),

  // 首页布局设置-查看
  getPage: params =>
    post(`/admin/v1/activity/page/get?actId=${params}`),
  // 编辑首页布局设置
  savePage: params =>
    post(`/admin/v1/activity/page/save`, params),
  // 编辑页面风格
  saveStyle: params =>
    post(`/admin/v1/activity/page/style/save`, params),
  // 查询页面风格
  getStyle: params =>
    post(`/admin/v1/activity/page/style/get?actId=${params}`),
}
